import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Top from '../components/SemaglutidePage/Top'
import Header from '../components/SemaglutidePage/Header'
import HowItWorks from '../components/SemaglutidePage/HowItWorks'
import Calculator from '../components/SemaglutidePage/Calculator'
import Live from '../components/SemaglutidePage/Live'
import BenefitsBlocks from '../components/SemaglutidePage/BenefitsBlocks'
import Benefits from '../components/SemaglutidePage/Benefits'
import Easy from '../components/SemaglutidePage/Easy'
import Protocols from '../components/SemaglutidePage/Protocols'
import Faq from '../components/SemaglutidePage/Faq'
import FoodDrug from '../components/SemaglutidePage/FoodDrug'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Header header={data.header} />,
      priority: data.header?.priority || 99
    },
    {
      render: <HowItWorks howItWorks={data.howItWorks} />,
      priority: data.howItWorks?.priority || 99
    },
    {
      render: <Calculator calculator={data.calculator} />,
      priority: data.calculator?.priority || 99
    },
    {
      render: <Live live={data.live} />,
      priority: data.live?.priority || 99
    },
    {
      render: <BenefitsBlocks benefitsBlocks={data.benefitsBlocks} />,
      priority: data.benefitsBlocks?.priority || 99
    },
    {
      render: <Benefits benefits={data.benefits} />,
      priority: data.benefits?.priority || 99
    },
    {
      render: <Easy easy={data.easy} />,
      priority: data.easy?.priority || 99
    },
    {
      render: <Protocols protocols={data.protocols} />,
      priority: data.protocols?.priority || 99
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99
    },
    {
      render: <FoodDrug foodDrug={data.foodDrug} />,
      priority: data.foodDrug?.priority || 99
    }
  ].sort((a, b) => (a.priority >= b.priority ? 1 : -1))

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      {(data?.faq?.faqList?.items || data?.faq?.items) && (
        <FaqSchema questions={data?.faq?.faqList?.items || data?.faq?.items} />
      )}
      <Top top={data.top} />
      {components.map(component => component.render)}
    </Layout>
  )
}
